import React from "react"
import Head from "../components/Head"
import Container from "../components/Container.js"
import HeaderTitle from "../components/HeaderTitle.js"
import { Link } from "gatsby"
import TableOfContents from "../components/TableOfContents"
import TableOfContentsHeader from "../components/TableOfContents/Header"
import TableOfContentsRow from "../components/TableOfContents/Row"
import TableOfContentsSection from "../components/TableOfContents/Section"
import TextSectionLink from "../components/TextContainer/Link"

export default function AdvancedTableOfContents() {
  return (
    <>
      <Head />
      <Container lessVerticalSpace>
        <HeaderTitle
          title={<Link to="/#advanced-course">JavaScript Simplified</Link>}
          subtitle="Advanced Course"
        />
      </Container>
      <TableOfContents>
        <TableOfContentsSection>
          <TableOfContentsHeader>Introduction</TableOfContentsHeader>
          <TableOfContentsRow duration="2:04" title="Welcome" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Advanced Features</TableOfContentsHeader>
          <TableOfContentsRow duration="18:53" title="Destructuring" />
          <TableOfContentsRow
            duration="14:14"
            title="Spread And Rest Operator"
          />
          <TableOfContentsRow
            duration="5:08"
            title="Enhanced Object Literals"
          />
          <TableOfContentsRow duration="5:35" title="Default Parameters" />
          <TableOfContentsRow duration="4:30" title="Null Coalescing" />
          <TableOfContentsRow
            duration="9:01"
            title="Optional Chaining"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-advanced/729105-Advanced-Features/2125546-07-Optional-Chaining"
          />
          <TableOfContentsRow duration="12:49" title="Maps" />
          <TableOfContentsRow duration="5:54" title="Sets" />
          <TableOfContentsRow duration="10:19" title="Symbols" />
          <TableOfContentsRow
            duration="12:15"
            title="Generators And Iterators"
          />
          <TableOfContentsRow
            duration="9:13"
            title="Object Getters And Setters"
          />
          <TableOfContentsRow duration="7:31" title="Bind" />
          <TableOfContentsRow duration="4:51" title="Call And Apply" />
          <TableOfContentsRow
            duration="43:18"
            title="Minesweeper Project"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-advanced/729105-Advanced-Features/2125544-15-Minesweeper-Project"
          />
          <TableOfContentsRow
            duration="3:14"
            title="Math Solver Introduction"
          />
          <TableOfContentsRow
            duration="34:41"
            title="Math Solver Walkthrough"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Using Future JavaScript</TableOfContentsHeader>
          <TableOfContentsRow duration="10:00" title="ES Updates" />
          <TableOfContentsRow duration="5:00" title="Polyfill" />
          <TableOfContentsRow duration="10:45" title="Transpile And Babel" />
          <TableOfContentsRow duration="3:42" title="Bundlers" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>
            Object Oriented Programming
          </TableOfContentsHeader>
          <TableOfContentsRow duration="10:46" title="Prototypes" />
          <TableOfContentsRow duration="10:31" title="Class Basics" />
          <TableOfContentsRow duration="8:40" title="Class Inheritance" />
          <TableOfContentsRow
            duration="10:26"
            title="Public, Private, And Protected Properties"
          />
          <TableOfContentsRow duration="36:37" title="ATM CLI Project" />
          <TableOfContentsRow
            duration="1:51"
            title="Calculator Project Introduction"
          />
          <TableOfContentsRow
            duration="34:18"
            title="Calculator Project Walkthrough"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Functional Programming</TableOfContentsHeader>
          <TableOfContentsRow
            duration="2:53"
            title="What Is Functional Programming"
          />
          <TableOfContentsRow duration="11:46" title="Pure Functions" />
          <TableOfContentsRow duration="8:05" title="Immutability" />
          <TableOfContentsRow duration="11:07" title="Higher Order Functions" />
          <TableOfContentsRow duration="6:22" title="Function Composition" />
          <TableOfContentsRow duration="8:40" title="Currying" />
          <TableOfContentsRow
            duration="40:25"
            title="Minesweeper Functional Programming Project"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Testing</TableOfContentsHeader>
          <TableOfContentsRow
            duration="4:54"
            title="What Is Testing And Why Is It Important"
          />
          <TableOfContentsRow duration="11:04" title="Advanced Debugging" />
          <TableOfContentsRow
            duration="16:53"
            title="Jest Basics"
            previewLink="https://courses.webdevsimplified.com/courses/javascript-simplified-advanced/729109-Testing/2126286-38-Jest-Basics"
          />
          <TableOfContentsRow duration="8:00" title="Types Of Tests" />
          <TableOfContentsRow duration="22:07" title="Unit Test Project" />
          <TableOfContentsRow
            duration="12:46"
            title="Integration Test Project"
          />
          <TableOfContentsRow
            duration="25:28"
            title="End To End Test Project"
          />
          <TableOfContentsRow duration="5:40" title="Test Driven Development" />
          <TableOfContentsRow
            duration="10:02"
            title="How To Know What To Test"
          />
          <TableOfContentsRow duration="6:23" title="Jest With ES6 Modules" />
          <TableOfContentsRow
            duration="6:46"
            title="Math Solver Test Walkthrough"
          />
          <TableOfContentsRow
            duration="1:02"
            title="Minesweeper Test Introduction"
          />
          <TableOfContentsRow
            duration="38:44"
            title="Minesweeper Test Walkthrough"
          />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Security</TableOfContentsHeader>
          <TableOfContentsRow duration="16:26" title="NPM Audit" />
          <TableOfContentsRow duration="7:33" title="Cross Site Scripting" />
          <TableOfContentsRow duration="4:59" title="Sanitize User Input" />
          <TableOfContentsRow duration="6:59" title="All Your Code Is Public" />
          <TableOfContentsRow duration="5:40" title="Never Trust The Client" />
          <TableOfContentsRow duration="5:48" title="Cookies" />
          <TableOfContentsRow duration="50:25" title="Weather App Project" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Clean Code</TableOfContentsHeader>
          <TableOfContentsRow duration="6:41" title="Clean Code Basics" />
          <TableOfContentsRow duration="6:35" title="Code Order" />
          <TableOfContentsRow duration="8:52" title="When To Use Comments" />
          <TableOfContentsRow duration="8:36" title="Coupling And Cohesion" />
          <TableOfContentsRow duration="14:22" title="Facade Pattern" />
          <TableOfContentsRow duration="10:16" title="Guard Clauses" />
          <TableOfContentsRow
            duration="16:07"
            title="Composition Vs Inheritance"
          />
          <TableOfContentsRow duration="5:59" title="Don't Repeat Yourself" />
          <TableOfContentsRow duration="1:30" title="Color Game Introduction" />
          <TableOfContentsRow duration="50:21" title="Color Game Walkthrough" />
        </TableOfContentsSection>
        <TableOfContentsSection>
          <TableOfContentsHeader>Conclusion</TableOfContentsHeader>
          <TableOfContentsRow duration="5:24" title="What's Next" />
        </TableOfContentsSection>
        <div className="mt-12">
          <div className="mb-3">
            <TextSectionLink local href="/beginner-table-of-contents">
              Beginner Course Video List
            </TextSectionLink>
          </div>
          <div className="mb-3">
            <TextSectionLink local href="/interview-table-of-contents">
              Interviews Video List
            </TextSectionLink>
          </div>
          <div>
            <TextSectionLink local href="/bonus-projects-table-of-contents">
              Bonus Projects Video List
            </TextSectionLink>
          </div>
        </div>
      </TableOfContents>
      <div className="my-12 text-2xl text-center">
        <TextSectionLink local href="/#advanced-course">
          Back To Landing Page
        </TextSectionLink>
      </div>
    </>
  )
}
